import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
// Mui
import { LoadingButton } from '@mui/lab';
import {
  Button,
  DialogActions,
  DialogTitle,
  IconButton,
  InputAdornment,
  Stack,
} from '@mui/material';
// Component
import { changeMyPasswordAPI } from '@api/main';
import CustomDialog from '@components/CustomDialog';
import FormProvider, { RHFTextField } from '@components/hook-form';
import Iconify from '@components/iconify';

type Props = {
  open: boolean;
  close: () => void;
};

export default function ChangePasswordModal({ open, close }: Props) {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const ChangePasswordModalSchema = yup.object().shape({
    oldPassword: yup.string().required('Trường này không được để trống'),
    newPassword: yup
      .string()
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}$/,
        'Mật khẩu phải có ít nhất 8 ký tự, bao gồm ít nhất 1 chữ thường, 1 chữ hoa và 1 số,và kí tự đặc biệt.'
      )
      .max(128),
    confirmNewPassword: yup.string().oneOf([yup.ref('newPassword'), null], 'Mật khẩu không khớp'),
  });

  const defaultValues = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  };

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(ChangePasswordModalSchema),
    defaultValues,
  });

  const {
    formState: { isValid, isSubmitting },
    handleSubmit,
  } = methods;

  const onSubmit = async (data: any) => {
    try {
      delete data.confirmNewPassword;
      await changeMyPasswordAPI(data);
      enqueueSnackbar('Thành công', {
        variant: 'success',
      });
      close();
    } catch (error) {
      enqueueSnackbar('Sai mật khẩu', {
        variant: 'error',
      });
    }
  };

  return (
    <CustomDialog open={open} onClose={close}>
      <DialogTitle>Đổi mật khẩu</DialogTitle>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} sx={{ p: 3 }}>
          <RHFTextField
            name="oldPassword"
            type={showPassword ? 'text' : 'password'}
            autoFocus
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            label="Mật khẩu cũ"
            isRequired
          />
          <RHFTextField
            name="newPassword"
            type={showNewPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowNewPassword(!showNewPassword)} edge="end">
                    <Iconify icon={showNewPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            label="Mật khẩu mới"
            isRequired
          />
          <RHFTextField
            name="confirmNewPassword"
            type={showConfirmPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    edge="end"
                  >
                    <Iconify icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            label="Xác nhận mật khẩu"
            isRequired
          />
        </Stack>
        <DialogActions>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
            disabled={!isValid}
          >
            Lưu
          </LoadingButton>
          <Button variant="outlined" color="inherit" onClick={close}>
            Hủy
          </Button>
        </DialogActions>
      </FormProvider>
    </CustomDialog>
  );
}
