export const DefaultPagination = {
  pageSize: 10,
  pageNumber: 1,
} as const;

export const QueryParamsGetAll = {
  pageSize: 100,
  pageNumber: 1,
} as const;

export const StatusEnum = {
  Reserved: 1,
  Assigned: 2,
};

export const StatusEnumDisplayConfig = {
  [StatusEnum.Assigned]: { title: 'Assigned' },
  [StatusEnum.Reserved]: { title: 'Reserved' },
};

export const STALE_TIME = {
  ONE_HOUR: 3600000,
  FIVE_MINUTES: 300000,
};

export enum OrderByTypeEnum {
  Asc = 0,
  Desc = 1,
}

export const KeywordOperatorEnum = {
  StartWith: 1,
  EndsWith: 2,
  Contain: 3,
  Equals: 4,
  Sku: 5,
};

export const AllOption = { value: -1, label: 'Tất cả' } as const;

export enum StoreStatusEnum {
  Waiting = 1,
  Confirmed = 2,
  Rejected = 3,
}

export const StoreStatusKeyEnum = {
  Waiting: 1,
  Confirmed: 2,
  Rejected: 3,
};

export enum UploadProductStatusEnum {
  InProcess = 1,
  Success = 2,
}

export enum ExpiryUnitEnum {
  Date = 1,
  Month = 3,
  Year = 4,
  Forever = 5,
}

export enum ProductItemStatusEnum {
  InStock = 100,
  Sold = 1000,
  Deleted = 9999,
}

export const StoreStatusDisplayConfig = {
  [StoreStatusKeyEnum.Waiting]: { title: 'Đang chờ' },
  [StoreStatusKeyEnum.Confirmed]: { title: 'Đã xác nhận' },
  [StoreStatusKeyEnum.Rejected]: { title: 'Từ chối' },
};

export const KeywordOperatorDisplayConfig = {
  [KeywordOperatorEnum.StartWith]: { title: 'Start with' },
  [KeywordOperatorEnum.EndsWith]: { title: 'End with' },
  [KeywordOperatorEnum.Contain]: { title: 'Contain' },
  [KeywordOperatorEnum.Equals]: { title: 'Equals' },
  [KeywordOperatorEnum.Sku]: { title: 'SKU' },
};

export const EditInternalSKUsReasonEnum = {
  ProductFinalConfig: 1,
  ErrorCorrection: 2,
  Other: 3,
};

export const EditInternalSKUsReasonDisplayConfig = {
  [EditInternalSKUsReasonEnum.ProductFinalConfig]: { title: 'Product final configuration' },
  [EditInternalSKUsReasonEnum.ErrorCorrection]: { title: 'Error correction' },
  [EditInternalSKUsReasonEnum.Other]: { title: 'Other' },
};

export const ProductStatusEnum = {
  Activated: 1,
  Deactivated: 2,
};

export const ProductStatusDisplayConfig = {
  [ProductStatusEnum.Activated]: { title: 'Activated' },
  [ProductStatusEnum.Deactivated]: { title: 'Deactivated' },
};

export const BANK = {
  VietcomBank: 1,
  TechcomBank: 2,
  VietinBank: 3,
};

export const BANK_COLORS = {
  [BANK.VietcomBank]: '#E9FCD4',
  [BANK.TechcomBank]: '#FFE7D9',
  [BANK.VietinBank]: '#D1EDFF',
};

export const BANK_LOGO = {
  [BANK.VietcomBank]: {
    full: '/vietcombank.png',
    sort: '/VCB.png',
  },
  [BANK.TechcomBank]: {
    full: '/techcombank.png',
    sort: '/TCB.png',
  },
  [BANK.VietinBank]: {
    full: '/vietinbank.png',
    sort: '/VTB.png',
  },
};

export enum FILLTER_TIME_ENUMS {
  Last7Days = 1,
  Last30Days = 2,
}

export enum UserTypeEnum {
  Admin = 0,
  Vendor = 1,
  Register = 2,
}
export enum TRANSACTION_STATUS {
  CashBuying = 1,
  TransferBuying = 2,
  Sellings = 3,
}

export const CURRENCY = {
  USD: {
    code: 'USD',
    flag: '/Flag/USD.png',
    id: 1,
  },
  EUR: {
    code: 'EUR',
    flag: '/Flag/EUR.png',
    id: 2,
  },
  GBP: {
    code: 'GBP',
    flag: '/Flag/GBP.png',
    id: 3,
  },
  JPY: {
    code: 'JPY',
    flag: '/Flag/JPY.png',
    id: 4,
  },
  AUD: {
    code: 'AUD',
    flag: '/Flag/AUD.png',
    id: 5,
  },
  SGD: {
    code: 'SGD',
    flag: '/Flag/SGD.png',
    id: 6,
  },
  THB: {
    code: 'THB',
    flag: '/Flag/THB.png',
    id: 7,
  },
  CAD: {
    code: 'CAD',
    flag: '/Flag/CAD.png',
    id: 8,
  },
  CHF: {
    code: 'CHF',
    flag: '/Flag/CHF.png',
    id: 9,
  },
  HKD: {
    code: 'HKD',
    flag: '/Flag/HKD.png',
    id: 10,
  },
  CNY: {
    code: 'CNY',
    flag: '/Flag/CNY.png',
    id: 11,
  },
  DKK: {
    code: 'DKK',
    flag: '/Flag/DKK.png',
    id: 12,
  },
  INR: {
    code: 'INR',
    flag: '/Flag/INR.png',
    id: 13,
  },
  KRW: {
    code: 'KRW',
    flag: '/Flag/KRW.png',
    id: 14,
  },
  KWD: {
    code: 'KWD',
    flag: '/Flag/KWD.png',
    id: 15,
  },
  MYR: {
    code: 'MYR',
    flag: '/Flag/MYR.png',
    id: 16,
  },
  NOK: {
    code: 'NOK',
    flag: '/Flag/NOK.png',
    id: 17,
  },
  RUB: {
    code: 'RUB',
    flag: '/Flag/RUB.png',
    id: 18,
  },
  SAR: {
    code: 'SAR',
    flag: '/Flag/SAR.png',
    id: 19,
  },
  SEK: {
    code: 'SEK',
    flag: '/Flag/SEK.png',
    id: 20,
  },
  LAK: {
    code: 'LAK',
    flag: '/Flag/LAK.png',
    id: 21,
  },
  NZD: {
    code: 'NZD',
    flag: '/Flag/NZD.png',
    id: 22,
  },
};

export const UserRoleTypeEnum = {
  Admin: 0,
  Staff: 1,
};

export enum CaenEnum {
  Product = 1,
  Service = 2,
}

export const CaenEnumDisplayConfig = {
  [CaenEnum.Product]: { title: 'Bán sản phẩm' },
  [CaenEnum.Service]: { title: 'Dịch vụ' },
};

export const StoreTypeEnum = {
  Email: 1,
  Application: 2,
  Account: 3,
  ProductOther: 4,
  IncreaseInteraction: 5,
  ApplicationService: 6,
  Blockchain: 7,
  ServiceOther: 8,
};

export const StoreTypeDisplayConfig = {
  [StoreTypeEnum.Email]: { title: 'Email' },
  [StoreTypeEnum.Application]: { title: 'Phần mềm' },
  [StoreTypeEnum.Account]: { title: 'Tài khoản' },
  [StoreTypeEnum.ProductOther]: { title: 'Khác' },
  [StoreTypeEnum.IncreaseInteraction]: { title: 'Tăng tương tác' },
  [StoreTypeEnum.ApplicationService]: { title: 'Dịch vụ phần mềm' },
  [StoreTypeEnum.Blockchain]: { title: 'Blockchain' },
  [StoreTypeEnum.ServiceOther]: { title: 'Dịch vụ khác' },
};

export const ProductTypeEnum = {
  /* Products */
  //  Email
  Gmail: { value: 1, storeType: StoreTypeEnum.Email },
  HotMail: { value: 2, storeType: StoreTypeEnum.Email },
  OutlookMail: { value: 3, storeType: StoreTypeEnum.Email },
  RuMail: { value: 4, storeType: StoreTypeEnum.Email },
  DomainMail: { value: 5, storeType: StoreTypeEnum.Email },
  YahooMail: { value: 6, storeType: StoreTypeEnum.Email },
  ProtonMail: { value: 7, storeType: StoreTypeEnum.Email },
  OtherMail: { value: 8, storeType: StoreTypeEnum.Email },
  // PM
  PMFB: { value: 9, storeType: StoreTypeEnum.Application },
  PMGoogle: { value: 10, storeType: StoreTypeEnum.Application },
  PMYoutube: { value: 11, storeType: StoreTypeEnum.Application },
  PMTienAo: { value: 12, storeType: StoreTypeEnum.Application },
  PMPTC: { value: 13, storeType: StoreTypeEnum.Application },
  PMCapcha: { value: 14, storeType: StoreTypeEnum.Application },
  PMOffer: { value: 15, storeType: StoreTypeEnum.Application },
  PMPTU: { value: 16, storeType: StoreTypeEnum.Application },
  PMKhac: { value: 17, storeType: StoreTypeEnum.Application },
  // Account
  AccountFB: { value: 18, storeType: StoreTypeEnum.Account },
  AccountBM: { value: 19, storeType: StoreTypeEnum.Account },
  AccountZalo: { value: 20, storeType: StoreTypeEnum.Account },
  AccountTwitter: { value: 21, storeType: StoreTypeEnum.Account },
  AccountTelegram: { value: 22, storeType: StoreTypeEnum.Account },
  AccountInstagram: { value: 23, storeType: StoreTypeEnum.Account },
  AccountShopee: { value: 24, storeType: StoreTypeEnum.Account },
  AccountDiscord: { value: 25, storeType: StoreTypeEnum.Account },
  AccountTikTok: { value: 26, storeType: StoreTypeEnum.Account },
  KeyDietVirus: { value: 27, storeType: StoreTypeEnum.Account },
  KeyWindow: { value: 28, storeType: StoreTypeEnum.Account },
  AccountKhac: { value: 29, storeType: StoreTypeEnum.Account },
  // Other
  TheNap: { value: 30, storeType: StoreTypeEnum.ProductOther },
  VPS: { value: 31, storeType: StoreTypeEnum.ProductOther },
  Khac: { value: 32, storeType: StoreTypeEnum.ProductOther },

  /* Services */
  // Increase Interaction
  IncreaseFacebook: { value: 33, storeType: StoreTypeEnum.IncreaseInteraction },
  IncreaseTiktok: { value: 34, storeType: StoreTypeEnum.IncreaseInteraction },
  IncreaseGoogle: { value: 35, storeType: StoreTypeEnum.IncreaseInteraction },
  IncreaseTelegram: { value: 36, storeType: StoreTypeEnum.IncreaseInteraction },
  IncreaseShopee: { value: 37, storeType: StoreTypeEnum.IncreaseInteraction },
  IncreaseDiscord: { value: 38, storeType: StoreTypeEnum.IncreaseInteraction },
  IncreaseTwitter: { value: 39, storeType: StoreTypeEnum.IncreaseInteraction },
  IncreaseYoutube: { value: 40, storeType: StoreTypeEnum.IncreaseInteraction },
  IncreaseZalo: { value: 41, storeType: StoreTypeEnum.IncreaseInteraction },
  IncreaseInstagram: { value: 42, storeType: StoreTypeEnum.IncreaseInteraction },
  IncreaseKhac: { value: 43, storeType: StoreTypeEnum.IncreaseInteraction },
  // App Service
  CodeTool: { value: 44, storeType: StoreTypeEnum.ApplicationService },
  Graphic: { value: 45, storeType: StoreTypeEnum.ApplicationService },
  VideoTool: { value: 46, storeType: StoreTypeEnum.ApplicationService },
  AppServiceKhac: { value: 47, storeType: StoreTypeEnum.ApplicationService },
  // Blockchain
  Crypto: { value: 48, storeType: StoreTypeEnum.Blockchain },
  NFT: { value: 49, storeType: StoreTypeEnum.Blockchain },
  CoinList: { value: 50, storeType: StoreTypeEnum.Blockchain },
  BlockchainKhac: { value: 51, storeType: StoreTypeEnum.Blockchain },
  // Other
  ServiceKhac: { value: 52, storeType: StoreTypeEnum.ServiceOther },
};

export const ProductTypeEnumDisplayConfig = {
  [ProductTypeEnum.Gmail.value]: { title: 'Gmail (3%)', storeType: StoreTypeEnum.Email },
  [ProductTypeEnum.HotMail.value]: { title: 'HotMail (3%)', storeType: StoreTypeEnum.Email },
  [ProductTypeEnum.OutlookMail.value]: {
    title: 'OutlookMail (3%)',
    storeType: StoreTypeEnum.Email,
  },
  [ProductTypeEnum.RuMail.value]: { title: 'RuMail (3%)', storeType: StoreTypeEnum.Email },
  [ProductTypeEnum.DomainMail.value]: { title: 'DomainMail (3%)', storeType: StoreTypeEnum.Email },
  [ProductTypeEnum.YahooMail.value]: { title: 'YahooMail (3%)', storeType: StoreTypeEnum.Email },
  [ProductTypeEnum.ProtonMail.value]: { title: 'ProtonMail (3%)', storeType: StoreTypeEnum.Email },
  [ProductTypeEnum.OtherMail.value]: {
    title: 'Loại mail khác (3%)',
    storeType: StoreTypeEnum.Email,
  },
  [ProductTypeEnum.PMFB.value]: { title: 'Phần mềm FB (3%)', storeType: StoreTypeEnum.Application },
  [ProductTypeEnum.PMGoogle.value]: {
    title: 'Phần mềm Google (6%)',
    storeType: StoreTypeEnum.Application,
  },
  [ProductTypeEnum.PMYoutube.value]: {
    title: 'Phần mềm Youtube (6%)',
    storeType: StoreTypeEnum.Application,
  },
  [ProductTypeEnum.PMTienAo.value]: {
    title: 'Phần mềm Tiền Ảo (6%)',
    storeType: StoreTypeEnum.Application,
  },
  [ProductTypeEnum.PMPTC.value]: {
    title: 'Phần mềm PTC (6%)',
    storeType: StoreTypeEnum.Application,
  },
  [ProductTypeEnum.PMCapcha.value]: {
    title: 'Phần mềm Capcha (6%)',
    storeType: StoreTypeEnum.Application,
  },
  [ProductTypeEnum.PMOffer.value]: {
    title: 'Phần mềm Offer (6%)',
    storeType: StoreTypeEnum.Application,
  },
  [ProductTypeEnum.PMPTU.value]: {
    title: 'Phần mềm PTU (6%)',
    storeType: StoreTypeEnum.Application,
  },
  [ProductTypeEnum.PMKhac.value]: {
    title: 'Phần mềm Khác (6%)',
    storeType: StoreTypeEnum.Application,
  },
  [ProductTypeEnum.AccountFB.value]: {
    title: 'Tài khoản FB (3%)',
    storeType: StoreTypeEnum.Account,
  },
  [ProductTypeEnum.AccountBM.value]: {
    title: 'Tài khoản BM (3%)',
    storeType: StoreTypeEnum.Account,
  },
  [ProductTypeEnum.AccountZalo.value]: {
    title: 'Tài khoản Zalo (3%)',
    storeType: StoreTypeEnum.Account,
  },
  [ProductTypeEnum.AccountTwitter.value]: {
    title: 'Tài khoản Twitter (3%)',
    storeType: StoreTypeEnum.Account,
  },
  [ProductTypeEnum.AccountTelegram.value]: {
    title: 'Tài khoản Telegram (4%)',
    storeType: StoreTypeEnum.Account,
  },
  [ProductTypeEnum.AccountInstagram.value]: {
    title: 'Tài khoản Instagram (3%)',
    storeType: StoreTypeEnum.Account,
  },
  [ProductTypeEnum.AccountShopee.value]: {
    title: 'Tài khoản Shopee (3%)',
    storeType: StoreTypeEnum.Account,
  },
  [ProductTypeEnum.AccountDiscord.value]: {
    title: 'Tài khoản Discord (3%)',
    storeType: StoreTypeEnum.Account,
  },
  [ProductTypeEnum.AccountTikTok.value]: {
    title: 'Tài khoản TikTok (3%)',
    storeType: StoreTypeEnum.Account,
  },
  [ProductTypeEnum.KeyDietVirus.value]: {
    title: 'Key Diệt Virus (3%)',
    storeType: StoreTypeEnum.Account,
  },
  [ProductTypeEnum.KeyWindow.value]: { title: 'Key Window (3%)', storeType: StoreTypeEnum.Account },
  [ProductTypeEnum.AccountKhac.value]: {
    title: 'Tài khoản khác (3%)',
    storeType: StoreTypeEnum.Account,
  },
  [ProductTypeEnum.TheNap.value]: { title: 'Thẻ nạp (1%)', storeType: StoreTypeEnum.ProductOther },
  [ProductTypeEnum.VPS.value]: { title: 'VPS (10%)', storeType: StoreTypeEnum.ProductOther },
  [ProductTypeEnum.Khac.value]: { title: 'Khác (3%)', storeType: StoreTypeEnum.ProductOther },
  [ProductTypeEnum.IncreaseFacebook.value]: {
    title: 'Dịch vụ Facebook (3%)',
    storeType: StoreTypeEnum.IncreaseInteraction,
  },
  [ProductTypeEnum.IncreaseTiktok.value]: {
    title: 'Dịch vụ Tiktok (3%)',
    storeType: StoreTypeEnum.IncreaseInteraction,
  },
  [ProductTypeEnum.IncreaseGoogle.value]: {
    title: 'Dịch vụ Google (3%)',
    storeType: StoreTypeEnum.IncreaseInteraction,
  },
  [ProductTypeEnum.IncreaseTelegram.value]: {
    title: 'Dịch vụ Telegram (3%)',
    storeType: StoreTypeEnum.IncreaseInteraction,
  },
  [ProductTypeEnum.IncreaseShopee.value]: {
    title: 'Dịch vụ Shopee (3%)',
    storeType: StoreTypeEnum.IncreaseInteraction,
  },
  [ProductTypeEnum.IncreaseDiscord.value]: {
    title: 'Dịch vụ Discord (3%)',
    storeType: StoreTypeEnum.IncreaseInteraction,
  },
  [ProductTypeEnum.IncreaseTwitter.value]: {
    title: 'Dịch vụ Twitter (3%)',
    storeType: StoreTypeEnum.IncreaseInteraction,
  },
  [ProductTypeEnum.IncreaseYoutube.value]: {
    title: 'Dịch vụ Youtube (3%)',
    storeType: StoreTypeEnum.IncreaseInteraction,
  },
  [ProductTypeEnum.IncreaseZalo.value]: {
    title: 'Dịch vụ Zalo (3%)',
    storeType: StoreTypeEnum.IncreaseInteraction,
  },
  [ProductTypeEnum.IncreaseInstagram.value]: {
    title: 'Dịch vụ Instagram (3%)',
    storeType: StoreTypeEnum.IncreaseInteraction,
  },
  [ProductTypeEnum.IncreaseKhac.value]: {
    title: 'Dịch vụ khác (3%)',
    storeType: StoreTypeEnum.IncreaseInteraction,
  },
  [ProductTypeEnum.CodeTool.value]: {
    title: 'Dịch vụ code tool (6%)',
    storeType: StoreTypeEnum.ApplicationService,
  },

  [ProductTypeEnum.Graphic.value]: {
    title: 'Dịch vụ đồ họa (4%)',
    storeType: StoreTypeEnum.ApplicationService,
  },
  [ProductTypeEnum.VideoTool.value]: {
    title: 'Dịch vụ video (4%)',
    storeType: StoreTypeEnum.ApplicationService,
  },
  [ProductTypeEnum.AppServiceKhac.value]: {
    title: 'Dịch vụ tool khác (6%)',
    storeType: StoreTypeEnum.ApplicationService,
  },
  [ProductTypeEnum.Crypto.value]: { title: 'Crypto (3%)', storeType: StoreTypeEnum.Blockchain },
  [ProductTypeEnum.NFT.value]: { title: 'NFT (3%)', storeType: StoreTypeEnum.Blockchain },
  [ProductTypeEnum.CoinList.value]: {
    title: 'Coin list (4%)',
    storeType: StoreTypeEnum.Blockchain,
  },
  [ProductTypeEnum.BlockchainKhac.value]: {
    title: 'Blockchain khác (4%)',
    storeType: StoreTypeEnum.Blockchain,
  },
  [ProductTypeEnum.ServiceKhac.value]: {
    title: 'Dịch vụ khác (4%)',
    storeType: StoreTypeEnum.ServiceOther,
  },
};

export enum PhysicalFileTypeEnum {
  Cover = 1,
  Product = 2,
}

export const ExpiryUnitEnumDisplayConfig = {
  [ExpiryUnitEnum.Date]: { title: 'Ngày' },
  [ExpiryUnitEnum.Month]: { title: 'Tháng' },
  [ExpiryUnitEnum.Year]: { title: 'Năm' },
  [ExpiryUnitEnum.Forever]: { title: 'Vĩnh viễn' },
};

export enum TransactionTypeEnum {
  Deposit = 100,
  Withdraw = 200,
  Purchase = 300,
  Sale = 400,
  Refund = 500,
  Resale = 600,
}

export const TransactionTypeEnumDisplayConfig = {
  [TransactionTypeEnum.Deposit]: { title: 'Nạp tiền' },
  [TransactionTypeEnum.Withdraw]: { title: 'Rút tiền' },
  [TransactionTypeEnum.Purchase]: { title: 'Thanh toán' },
  [TransactionTypeEnum.Sale]: { title: 'Bán hàng' },
  [TransactionTypeEnum.Refund]: { title: 'Hoàn tiền' },
  [TransactionTypeEnum.Resale]: { title: 'Reseller' },
};

export enum OrderStatusEnum {
  WaitForConfirm = 100,
  WaitForConfirmPreOrder = 200,
  PreOrdered = 300,
  Processing = 500,
  Holding = 1000,
  Complained = 2000,
  Completed = 9000,
  Canceled = 9500,
  Failed = 9999,
}

export const OrderStatusDisplayConfig = {
  [OrderStatusEnum.WaitForConfirm]: { title: 'Chờ xác nhận' },
  [OrderStatusEnum.WaitForConfirmPreOrder]: { title: 'Chờ xác nhận đặt trước' },
  [OrderStatusEnum.PreOrdered]: { title: 'Đặt trước' },
  [OrderStatusEnum.Processing]: { title: 'Đang xử lý' },
  [OrderStatusEnum.Holding]: { title: 'Đang giữ' },
  [OrderStatusEnum.Complained]: { title: 'Đang khiếu nại' },
  [OrderStatusEnum.Completed]: { title: 'Hoàn thành' },
  [OrderStatusEnum.Canceled]: { title: 'Đã hủy' },
  [OrderStatusEnum.Failed]: { title: 'Thất bại' },
};

export enum TransactionStatusEnum {
  Pending = 500,
  Completed = 9000,
  Canceled = 9999,
}

export const TransactionStatusDisplayConfig = {
  [TransactionStatusEnum.Pending]: { title: 'Đang xử lý' },
  [TransactionStatusEnum.Completed]: { title: 'Hoàn thành' },
  [TransactionStatusEnum.Canceled]: { title: 'Đã hủy' },
};

export enum RatingEnum {
  VeryBad = 1,
  Bad = 2,
  Normal = 3,
  Good = 4,
  VeryGood = 5,
}

export const RatingDisplayConfig = {
  [RatingEnum.VeryBad]: { title: '⭐️' },
  [RatingEnum.Bad]: { title: '⭐️⭐️' },
  [RatingEnum.Normal]: { title: '⭐️⭐️⭐️' },
  [RatingEnum.Good]: { title: '⭐️⭐️⭐️⭐️' },
  [RatingEnum.VeryGood]: { title: '⭐️⭐️⭐️⭐️⭐️' },
};

export enum ComplaintStatusEnum {
  InProgress = 1000,
  Disputing = 2000,
  Accepted = 9000,
  Rejected = 9500,
  Canceled = 9999,
}

export const ComplaintStatusDisplayConfig = {
  [ComplaintStatusEnum.InProgress]: { title: 'Đang xử lý' },
  [ComplaintStatusEnum.Disputing]: { title: 'Đang tranh chấp' },
  [ComplaintStatusEnum.Accepted]: { title: 'Đã chấp nhận' },
  [ComplaintStatusEnum.Rejected]: { title: 'Đã từ chối' },
  [ComplaintStatusEnum.Canceled]: { title: 'Đã hủy' },
};

export enum ResaleStatusEnum {
  WaitingForConfirm = 500,
  Accepted = 9000,
  Canceled = 9999,
}

export const ResaleStatusDisplayConfig = {
  [ResaleStatusEnum.WaitingForConfirm]: { title: 'Chờ xác nhận' },
  [ResaleStatusEnum.Accepted]: { title: 'Đã chấp nhận' },
  [ResaleStatusEnum.Canceled]: { title: 'Đã hủy' },
};

export enum VoucherTypeEnum {
  Percent = 1,
  Amount = 2,
}

export const VoucherTypeDisplayConfig = {
  [VoucherTypeEnum.Percent]: { title: 'Giảm theo phần trăm' },
  [VoucherTypeEnum.Amount]: { title: 'Giảm theo số tiền' },
};
