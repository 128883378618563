import numeral from 'numeral';
import { isEmptyValue } from './utilities';

// ----------------------------------------------------------------------

type InputValue = string | number | null | undefined;

export function parseNumber(number: InputValue): number | undefined;
export function parseNumber(number: InputValue, defaultValue: number): number;
export function parseNumber(number: InputValue, defaultValue?: number) {
  if (isEmptyValue(number)) return defaultValue;
  const num = Number(number);
  return Number.isNaN(num) ? defaultValue : num;
}

export function fNumber(number: InputValue) {
  const num = Number(number);
  return Number.isNaN(num) ? number : Intl.NumberFormat('vi-VN').format(num);
}

export function fCurrency(number: InputValue) {
  const num = parseNumber(number, 0);
  return Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(num);
}

export function fPercent(number: InputValue) {
  const format = number ? numeral(Number(number) / 100).format('0.0%') : '';

  return result(format, '.0');
}

export function fShortenNumber(number: InputValue) {
  const format = number ? numeral(number).format('0.00a') : '';

  return result(format, '.00');
}

export function fData(number: InputValue) {
  const format = number ? numeral(number).format('0.0 b') : '';

  return result(format, '.0');
}

function result(format: string, key = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}
