import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '@auth/AuthGuard';
// layouts
import CompactLayout from '@layouts/compact';
import DashboardLayout from '@layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '@configs/config';
//
import {
  LoginPage,
  Page404,
  Users,
  ResetPasswordPage,
  VerifyCodePage,
  Stores,
  TransactionHistory,
  Rating,
  Orders,
  EditStore,
  ProductDetail,
  CreateProduct,
  CreateTransaction,
  ServiceOrders,
  Complaint,
  PreOrders,
  Resellers,
  Vouchers,
  CreateVoucher,
} from './elements';
import Dashboard from '@pages/dashboard';
// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'login',
          element: <LoginPage />,
        },
        {
          element: <CompactLayout />,
          children: [
            { path: 'reset-password', element: <ResetPasswordPage /> },
            { path: 'verify-code', element: <VerifyCodePage /> },
          ],
        },
      ],
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'dashboard', element: <Dashboard /> },
        { path: 'users', element: <Users /> },
        { path: 'stores', element: <Stores /> },
        { path: 'orders', element: <Orders /> },
        { path: 'transaction-history', element: <TransactionHistory /> },
        { path: 'rating', element: <Rating /> },
        { path: 'service-orders', element: <ServiceOrders /> },
        { path: 'complaints', element: <Complaint /> },
        { path: 'pre-orders', element: <PreOrders /> },
        { path: 'resellers', element: <Resellers /> },
        { path: 'vouchers', element: <Vouchers /> },
      ],
    },
    {
      path: '/stores',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'create', element: <EditStore /> },
        { path: ':storeId', element: <EditStore /> },
        { path: ':storeId/products/:productId', element: <ProductDetail /> },
        { path: ':storeId/products', element: <CreateProduct /> },
      ],
    },
    {
      path: '/transaction-history',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ path: 'create', element: <CreateTransaction /> }],
    },
    {
      path: '/vouchers',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ path: 'create', element: <CreateVoucher /> }],
    },
    {
      element: <CompactLayout />,
      children: [{ path: '404', element: <Page404 /> }],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
