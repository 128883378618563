// @mui
import { Stack, Box, useTheme } from '@mui/material';
// config
import { NAV } from '@configs/config';
// utils
import { hideScrollbarX } from '@utils/cssStyles';
// components
import Logo from '@components/logo';
import { NavSectionMini } from '@components/nav-section';
//
import { AdminNavConfig, navConfig } from './config';
import { useSelector } from 'src/store/store';
import { UserResponseModel } from '@api/types';
import { UserTypeEnum } from '@utils/constants';

// ----------------------------------------------------------------------

export default function NavMini() {
  const user: UserResponseModel = useSelector((state) => state.auth.userData);
  const theme = useTheme();

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_DASHBOARD_MINI },
        backgroundColor: theme.palette.primary.darker,
      }}
    >
      <Stack
        sx={{
          pb: 2,
          height: 1,
          position: 'fixed',
          width: NAV.W_DASHBOARD_MINI,
          borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          ...hideScrollbarX,
        }}
      >
        <Logo sx={{ mx: 'auto', my: 2 }} />

        <NavSectionMini
          data={user.userTypeId === UserTypeEnum.Admin ? AdminNavConfig : navConfig}
        />
      </Stack>
    </Box>
  );
}
