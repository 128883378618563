import { useGetDashboard } from '@hooks/query/dashboard';
import { Box, Grid, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { formatYAxisTickFn } from '@utils/utilities';
import { useMemo } from 'react';
import DashboardCard from './DashboardCard';
import DashboardChart from './DashboardChart';
// eslint-disable-next-line import/no-duplicates
import { vi } from 'date-fns/locale';
import useFilter from '@hooks/useFilter';
import { fCurrency, parseNumber } from '@utils/formatNumber';
// eslint-disable-next-line import/no-duplicates
import { endOfMonth, format, getMonth, getYear, startOfDay, startOfMonth } from 'date-fns';

const Dashboard = () => {
  const today = startOfDay(new Date()).getTime();
  const [filter, setFilter] = useFilter();
  const month = useMemo(() => parseNumber(filter.month, today), [filter.month, today]);

  const { data } = useGetDashboard({
    startTimeUnix: startOfMonth(month).getTime(),
    endTimeUnix: endOfMonth(month).getTime(),
  });

  const chartData = useMemo(() => {
    const revenueData = data?.data.revenueByDays;
    if (!revenueData) return [];
    //sort chart data follow by timestamp
    const sortedData = revenueData.sort((a, b) => a.dateTimeAtUnix - b.dateTimeAtUnix);

    const convertedData = sortedData.map((item) => {
      return { dateTime: format(new Date(item.dateTimeAtUnix), 'dd'), count: item.revenue };
    });

    return convertedData;
  }, [data]);

  const line = {
    title: 'Tổng doanh thu trong tháng',
    name: 'Doanh thu',
  };

  return (
    <Box p={{ xs: 2, lg: 0 }}>
      <Box display={'flex'} flexDirection={'column'}>
        <Typography variant="h4">
          Kinh doanh tháng {getMonth(month) + 1}/{getYear(month)}
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={vi}>
          <DatePicker
            views={['month', 'year']}
            label="Chọn tháng"
            maxDate={endOfMonth(today)}
            value={month}
            inputFormat="MM/yyyy"
            onChange={(newValue) => {
              setFilter({
                month: newValue?.getTime(),
              });
            }}
            renderInput={(params) => (
              <TextField
                sx={{ width: { xs: '100%', sm: 270 }, mt: 2.5, mb: 2.5 }}
                {...params}
                inputProps={{ ...params.inputProps, readOnly: true }}
              />
            )}
          />
        </LocalizationProvider>
        <Grid container spacing={2} width={'100%'}>
          <Grid xs={12} sm={4} item>
            <DashboardCard
              title="Số đơn hàng"
              icon="lets-icons:order"
              value={data?.data.totalOrder || 0}
            />
          </Grid>
          <Grid xs={12} sm={4} item>
            <DashboardCard
              title="Doanh số"
              icon="icon-park-outline:chart-line"
              value={fCurrency(data?.data.revenue)}
            />
          </Grid>
          <Grid xs={12} sm={4} item>
            <DashboardCard
              title="Sàn tạm giữ"
              icon="carbon:time"
              value={fCurrency(data?.data.totalHolding)}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={2.5}>
        <DashboardChart
          line={line}
          data={chartData}
          formatYAxisTickFn={formatYAxisTickFn}
          formatTooltipValueFn={fCurrency}
        />
      </Box>
    </Box>
  );
};

export default Dashboard;
