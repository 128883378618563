// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// locales
import ThemeLocalization from './locales';
// components
import { ConfirmationDialogProvider } from '@utils/contexts/ConfirmationDialog';
import { MotionLazyContainer } from './components/animate';
import { ThemeSettings } from './components/settings';
import SnackbarProvider from './components/snackbar';
import { QueryClient, QueryClientProvider } from 'react-query';
import { STALE_TIME } from '@utils/constants';
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: STALE_TIME.FIVE_MINUTES,
    },
  },
});

// ----------------------------------------------------------------------

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <MotionLazyContainer>
        <ThemeProvider>
          <ThemeSettings>
            <ThemeLocalization>
              <ConfirmationDialogProvider>
                <SnackbarProvider>
                  <Router />
                </SnackbarProvider>
              </ConfirmationDialogProvider>
            </ThemeLocalization>
          </ThemeSettings>
        </ThemeProvider>
      </MotionLazyContainer>
    </QueryClientProvider>
  );
}
