import { TextField, TextFieldProps } from '@mui/material';
import RequiredLabel from './RequiredLabel';
import { FieldError } from 'react-hook-form';
import { getHelperText } from './utils';

export type CommonTextFieldProps = Omit<TextFieldProps, 'error'> & {
  error?: FieldError;
  isRequired?: boolean;
};

function CommonTextField({ isRequired, label, error, ...other }: CommonTextFieldProps) {
  return (
    <TextField
      fullWidth
      label={<RequiredLabel isRequired={isRequired}>{label}</RequiredLabel>}
      error={!!error}
      helperText={getHelperText(error)}
      {...other}
    />
  );
}

export default CommonTextField;
