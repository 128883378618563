import {
  ApiPagingResponseModel,
  ChangePasswordModel,
  CreateProductApiInputModel,
  CreateStoreApiInputModel,
  CreateUserApiInputModel,
  FileHistoryResponseModel,
  ForgotPasswordApiInputModel,
  ProductItemModel,
  ProductListingResponseModel,
  RegisterApiInputModel,
  ResendConfirmationApiInputModel,
  ResetPasswordApiInputModel,
  SetEnabledUserApiInputModel,
  SetStoreEnabledApiInputModel,
  SetStoreStatusApiInputModel,
  StoreDetailForVendorResponseModel,
  StoreDetailResponseModel,
  StoreResponseModel,
  UpdateProfileModel,
  UpdateUserApiInputModel,
  UserConfirmationResponseModel,
  UserLoginInputModel,
  UserLoginResponseModel,
  UserResponseModel,
  VerifyEmailApiInputModel,
  ProductResponseModel,
  DownloadProductItemInStockResponseModel,
  CreateTransactionApiInputModel,
  OrderResponseModel,
  GetTransactionHistoryApiInputModel,
  TransactionHistoryResponseModel,
  ReplyReviewApiInputModel,
  RatingResponseModel,
  ComplaintsResponseModel,
  ResellerResponseModel,
  VoucherResponseModel,
  CreateVoucherApiInputModel,
  QueryParams,
  DashboardResponseModel,
} from '@api/types';
import axios, { handleRefreshToken } from './base';
import {
  CaenEnum,
  ComplaintStatusEnum,
  OrderStatusEnum,
  RatingEnum,
  ResaleStatusEnum,
} from '@utils/constants';

//#region AUTHENTICATION API

export const loginAPI = async (data: UserLoginInputModel) =>
  axios.post<UserLoginResponseModel>(`/auth/login`, data, {
    __auth: false,
  });

export const registerAPI = async (data: RegisterApiInputModel, lang: string) =>
  axios.post(`/auth/register-clinic?lang=${lang}`, data, {
    __auth: false,
  });

export const verifyEmailAPI = async (data: VerifyEmailApiInputModel) =>
  axios.post('/auth/verify', data, {
    __auth: false,
  });

export const resendConfirmationAPI = async (data: ResendConfirmationApiInputModel, lang: string) =>
  axios.post(`/auth/resend-confirmation?lang=${lang}`, data, { __auth: false });

export const getUserInfoAPI = async () => axios.get('/users/me');

export const forgotPasswordAPI = async (data: ForgotPasswordApiInputModel, lang: string) =>
  axios.post<UserConfirmationResponseModel>(`/auth/forgot-password?lang=${lang}`, data);

export const resetPasswordAPI = async (data: ResetPasswordApiInputModel, lang: string) =>
  axios.post(`/auth/reset-password?lang=${lang}`, data);

export const refreshToken = async () => handleRefreshToken();

//#endregion

// #region Users

export const getUserListAPI = async (params: QueryParams) =>
  axios.get<ApiPagingResponseModel<UserResponseModel>>('/users', {
    params,
  });

export const getUserByIdAPI = async (id: string) => axios.get<UserResponseModel>(`/users/${id}`);

export const createUserAPI = async (data: CreateUserApiInputModel) => axios.post('/users', data);

export const setEnabledUserAPI = async (userId: string, data: SetEnabledUserApiInputModel) =>
  axios.put(`/users/${userId}/set-enabled`, data);

export const deleteUserAPI = async (userId: string) => axios.delete(`/users/${userId}`);

export const updateUserAPI = async (userId: string, data: UpdateUserApiInputModel) =>
  axios.put(`/users/${userId}`, data);
// #endregion

export const changeMyPasswordAPI = async (data: ChangePasswordModel) => {
  return axios.put(`/users/change-password`, data);
};

export const getMyProfile = async () => {
  return axios.get(`/users/me`);
};

export const updateMyProfile = async (data: UpdateProfileModel) => {
  return axios.put(`users/me`, data);
};

// #region store

export const getStoreListAPI = async (params: QueryParams) => {
  return axios.get<ApiPagingResponseModel<StoreResponseModel>>('/stores', {
    params,
  });
};

export const createStoreAPI = (data: CreateStoreApiInputModel) => {
  return axios.post('/stores', data);
};

export const updateStoreAPI = (storeId: string, data: CreateStoreApiInputModel) => {
  return axios.put(`/stores/${storeId}`, data);
};

export const getStoreByIdForVendorAPI = async (storeId: string) => {
  return axios.get<StoreDetailForVendorResponseModel>(`/stores/${storeId}/for-admin`);
};

export const getStoreByIdForAdminAPI = async (storeId: string) => {
  return axios.get<StoreDetailResponseModel>(`/stores/${storeId}/for-admin`);
};

export const setStoreStatusAPI = (storeId: number, data: SetStoreStatusApiInputModel) =>
  axios.put(`/stores/${storeId}/set-status`, data);

export const setStoreEnabledAPI = (storeId: number, data: SetStoreEnabledApiInputModel) =>
  axios.put(`/stores/${storeId}/set-enabled`, data);

//#region  * Upload Image

export const createPhysicalFileAPI = async (data: {
  fileName: string;
  fileLength: number;
  physicalFileType: any;
  storeId: number | null;
  productId: number | null;
}) => {
  return axios.post(`/files/request-upload-file`, data);
};
export const putUploadImageAPI = async (preSignedURL: string, data: Blob) => {
  return axios.put(`${preSignedURL}`, data, {
    __auth: false,
    upload: data?.type,
  });
};
export const putUploadDoneAPI = async (fileId: number) => {
  return axios.put(`files/${fileId}/upload-done`);
};

export const getFileUrlsAPI = async (data: any) => {
  return axios.post(`/files/get-urls`, data);
};

export const getFileFromUrlAPI = async (url: string) => {
  return axios.get(url, { __auth: false, responseType: 'blob' });
};

//#endregion

//#region  * File
export const getFileProductHistoryAPI = async (productId: number) => {
  return axios.get<FileHistoryResponseModel[]>(`products/${productId}/upload-history`);
};
//#endregion

//#region  * Product
export const getProductDetailAPI = async (productId: number) => {
  return axios.get<ProductResponseModel>(`products/${productId}`);
};

export const getProductItemAPI = async (productId: number, params: QueryParams) =>
  axios.get<ApiPagingResponseModel<ProductItemModel>>(`/products/${productId}/stock`, { params });

export const deleteProductItemAPI = async (productId: number) => {
  return axios.delete(`/products/${productId}`);
};

export const createProductOfStoreAPI = (data: CreateProductApiInputModel) =>
  axios.put(`/products`, data);

export const getProductListAPI = async (storeId: number) => {
  return axios.post<ProductListingResponseModel[]>(`/products/search`, { storeId });
};

export const updateProductAPI = (productId: number, data: CreateProductApiInputModel) =>
  axios.put(`/products/${productId}`, data);

export const deleteInStockProductItemsAPI = (productId: number) => {
  return axios.delete(`/products/${productId}`);
};

export const downloadInStockItemAPI = async (productId: number) => {
  return axios.get<DownloadProductItemInStockResponseModel>(
    `/products/${productId}/download-in-stock-item`
  );
};
//#endregion

//#region  * Transaction
export const createTransactionAPI = async (data: CreateTransactionApiInputModel) => {
  return axios.post(`/transaction-history`, data);
};

export const getTransactionHistoryForAdminAPI = async (
  data: GetTransactionHistoryApiInputModel,
  params?: QueryParams
) => {
  return axios.post<ApiPagingResponseModel<TransactionHistoryResponseModel>>(
    `/transaction-history/search-for-admin`,
    data,
    {
      params,
    }
  );
};

//#endregion

//#region  * Orders

export const getOrdersListAPI = async (
  caenId: CaenEnum,
  {
    status,
    ...params
  }: QueryParams<{
    status?: OrderStatusEnum;
  }>
) => {
  return axios.post<ApiPagingResponseModel<OrderResponseModel>>(
    `/orders/search-for-manager`,
    { caenId, status },
    {
      params,
    }
  );
};

export const confirmServiceOrderAPI = async (orderId: number, data: { isAccepted: boolean }) => {
  return axios.put(`/orders/${orderId}/confirm-service`, data);
};

export const confirmPreOrderAPI = async (orderId: number, data: { isAccepted: boolean }) => {
  return axios.put(`/orders/${orderId}/confirm-pre-order`, data);
};

//#endregion

//#region Rating
export const getReviewListAPI = async (params: QueryParams, data: { rating?: RatingEnum }) => {
  return axios.post<ApiPagingResponseModel<RatingResponseModel>>(
    `/reviews/search/for-admin`,
    data,
    {
      params,
    }
  );
};

export const replyReviewAPI = async (data: ReplyReviewApiInputModel, reviewId: number) => {
  return axios.put(`/reviews/${reviewId}/reply`, data);
};

export const deleteReviewAPI = async (reviewId: number) => {
  return axios.delete(`/reviews/${reviewId}`);
};

//#endregion

//#region Complaints
export const getComplaintsListAPI = async (
  params: QueryParams,
  complaintStatusId?: ComplaintStatusEnum
) => {
  return axios.post<ApiPagingResponseModel<ComplaintsResponseModel>>(
    `/complaints/search`,
    {
      statusId: complaintStatusId,
    },
    {
      params,
    }
  );
};

export const confirmComplaintForVendorAPI = async (
  complaintId: number,
  data: { isAccepted: boolean }
) => {
  return axios.put(`/complaints/${complaintId}/confirm`, data);
};

export const confirmComplaintForAdminAPI = async (
  complaintId: number,
  data: { isAccepted: boolean }
) => {
  return axios.put(`/complaints/${complaintId}/resolve`, data);
};

//#endregion

//#region Resellers
export const getResellerListAPI = async (params: QueryParams, status?: ResaleStatusEnum) => {
  return axios.post<ApiPagingResponseModel<ResellerResponseModel>>(
    `/resales/search-for-vendor`,
    { status },
    {
      params,
    }
  );
};

export const acceptResellerAPI = async (resaleId: number) => {
  return axios.put(`/resales/${resaleId}/accept`);
};

export const rejectResellerAPI = async (resaleId: number) => {
  return axios.put(`/resales/${resaleId}/reject`);
};

//#endregion

//#region Discounts
export const getVoucherListAPI = async (params: QueryParams) => {
  return axios.get<ApiPagingResponseModel<VoucherResponseModel>>('/vouchers', {
    params,
  });
};

export const createVoucherAPI = async (data: CreateVoucherApiInputModel) => {
  return axios.post('/vouchers', data);
};

export const cancelVoucherAPI = async (voucherId: number) => {
  return axios.put(`/vouchers/${voucherId}/cancel`);
};

//#endregion

//#region Dashboard

export const getDashboardDataAPI = async (filter: {
  startTimeUnix: number;
  endTimeUnix: number;
}) => {
  return axios.post<DashboardResponseModel>(`/dashboard`, filter);
};

//#endregion
