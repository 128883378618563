import { ElementType, lazy, Suspense } from 'react';
// components
import LoadingScreen from '@components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

export const LoginPage = Loadable(lazy(() => import('@pages/auth/Login')));
export const VerifyCodePage = Loadable(lazy(() => import('@pages/auth/VerifyCodePage')));
export const ResetPasswordPage = Loadable(lazy(() => import('@pages/auth/ResetPasswordPage')));

export const Dashboard = Loadable(lazy(() => import('@pages/dashboard')));
export const Users = Loadable(lazy(() => import('@pages/users')));
export const Stores = Loadable(lazy(() => import('@pages/stores')));
export const Orders = Loadable(lazy(() => import('@pages/orders')));
export const TransactionHistory = Loadable(lazy(() => import('@pages/transaction-history')));
export const Rating = Loadable(lazy(() => import('@pages/rating')));
export const EditStore = Loadable(lazy(() => import('@pages/stores/EditStore')));
export const ProductDetail = Loadable(lazy(() => import('@pages/stores/ProductDetail')));
export const CreateProduct = Loadable(lazy(() => import('@pages/stores/CreateProduct')));
export const CreateTransaction = Loadable(
  lazy(() => import('@pages/transaction-history/CreateTransaction'))
);
export const ServiceOrders = Loadable(lazy(() => import('@pages/service-orders')));
export const Complaint = Loadable(lazy(() => import('@pages/complaint')));
export const PreOrders = Loadable(lazy(() => import('@pages/pre-orders')));
export const Resellers = Loadable(lazy(() => import('@pages/resellers')));
export const Vouchers = Loadable(lazy(() => import('@pages/voucher')));
export const CreateVoucher = Loadable(lazy(() => import('@pages/voucher/CreateVoucher')));

export const Page404 = Loadable(lazy(() => import('@pages/misc/Page404')));
