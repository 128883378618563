import { getDashboardDataAPI } from '@api/main';
import { useQuery } from 'react-query';

export const useGetDashboard = (filter: { startTimeUnix: number; endTimeUnix: number }) => {
  return useQuery(
    ['get-dashboard', filter],
    () => getDashboardDataAPI(filter),
    { staleTime: 1000 * 60 * 5 } // 5 minutes
  );
};
