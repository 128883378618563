import { MenuItem } from '@mui/material';
import { AllOption } from '@utils/constants';
import { isEmptyValue } from '@utils/utilities';
import { ChangeEventHandler, memo, useCallback, useMemo } from 'react';
import CommonTextField, { CommonTextFieldProps } from './CommonTextField';

export type SelectOption = {
  value?: string | number;
  label: string;
  disabled?: boolean;
};

export type SelectFieldProps = Omit<CommonTextFieldProps, 'onChange'> & {
  allOption?: boolean;
  options?: SelectOption[];
  onChange?: (value?: string | number) => void;
};

function SelectField({
  options,
  allOption,
  value,
  onChange,
  SelectProps = { native: false },
  children,
  ...other
}: SelectFieldProps) {
  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      // eslint-disable-next-line prefer-destructuring
      let value = event.target.value as string | number | undefined;
      if (allOption && value === AllOption.value) {
        value = undefined;
      }
      onChange?.(value);
    },
    [allOption, onChange]
  );

  const selectValue = useMemo(() => {
    if (allOption && isEmptyValue(value)) {
      return AllOption.value;
    }
    return value;
  }, [allOption, value]);

  return (
    <CommonTextField
      value={selectValue}
      onChange={handleChange}
      select
      SelectProps={SelectProps}
      {...other}
    >
      {allOption && (
        <MenuItem key={AllOption.value} value={AllOption.value}>
          {AllOption.label}
        </MenuItem>
      )}
      {options
        ? options.map((option) => (
            <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
              {option.label}
            </MenuItem>
          ))
        : children}
    </CommonTextField>
  );
}

export default memo(SelectField);
