import Iconify from '@components/iconify/Iconify';
import { Box, Card, Typography } from '@mui/material';
import React from 'react';

interface Props {
  title: string;
  value: number | string;
  icon: string;
}

const DashboardCard = ({ title, value, icon }: Props) => {
  return (
    <Card sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
      <Typography fontSize={16} color={'#718096'} fontWeight={500} mb={1}>
        {title}
      </Typography>
      <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
        <Iconify icon={icon} width={22} height={22} mr={1} />
        <Typography fontSize={28}>{value}</Typography>
      </Box>
    </Card>
  );
};

export default DashboardCard;
