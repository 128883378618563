import { getErrorMessage } from '@api/handleApiError';
import { getUserInfoAPI, refreshToken } from '@api/main';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Storage, STORAGE_KEYS } from '@utils/storage';
import { ILoginData, IUser } from './types';

interface IAuthState {
  isCheckingRememberedUser: boolean;
  isUserLoggedIn: boolean;
  refreshToken: boolean;
  userData: any;
}

const initialState: IAuthState = {
  isCheckingRememberedUser: true,
  isUserLoggedIn: false,
  refreshToken: false,
  userData: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    LOGIN: (state, action) => ({
      ...state,
      isUserLoggedIn: true,
      userData: action.payload.user,
    }),
    LOGOUT: (state) => ({
      ...state,
      isUserLoggedIn: false,
      userData: null,
    }),
    REFRESH_TOKEN: (state) => ({
      ...state,
      refreshToken: !state.refreshToken,
    }),
  },
  extraReducers(builder) {
    builder.addCase(checkRememberedUserAC.fulfilled, (state, action) => ({
      ...state,
      isCheckingRememberedUser: false,
      isUserLoggedIn: action.payload?.isUserLoggedIn,
      userData: action.payload?.user,
    }));
    builder.addCase(getUserInfoAC.fulfilled, (state, action) => ({
      ...state,
      userData: action.payload,
    }));
  },
});

export default authSlice.reducer;

const { LOGIN, LOGOUT, REFRESH_TOKEN } = authSlice.actions;

export const loginAC = (data: ILoginData) => {
  // ** Add to user, accessToken & refreshToken to localStorage
  Storage.setItem(STORAGE_KEYS.userData, data.user);
  Storage.setItem(STORAGE_KEYS.token, data.token);

  return LOGIN(data);
};

export const logoutAC = () => {
  // ** Remove user, accessToken & refreshToken from localStorage
  Storage.clear(STORAGE_KEYS.userData);
  Storage.clear(STORAGE_KEYS.token);

  return LOGOUT();
};

export const refreshTokenAC = () => REFRESH_TOKEN();

// ** Check remenbered user
export const checkRememberedUserAC = createAsyncThunk(
  'auth/CHECK_REMENBERED_USER_DONE',
  async () => {
    try {
      if (!!Storage.getItem(STORAGE_KEYS.userData) && !!Storage.getItem(STORAGE_KEYS.token)) {
        await refreshToken();
        return {
          isUserLoggedIn: true,
          user: Storage.getItem<IUser>(STORAGE_KEYS.userData),
        };
      } else {
        return {
          isUserLoggedIn: false,
          user: null,
        };
      }
    } catch (error) {
      return {
        isUserLoggedIn: false,
        user: null,
      };
    }
  }
);

export const getUserInfoAC = createAsyncThunk('auth/UPDATE_USER', async () => {
  try {
    const userRes = await getUserInfoAPI();
    Storage.setItem(STORAGE_KEYS.userData, {
      ...userRes.data,
    });
    return {
      ...userRes.data,
    };
  } catch (error) {
    console.log(getErrorMessage(error));
  }
});
