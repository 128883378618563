import { createContext, useEffect } from 'react';
import { checkRememberedUserAC, getUserInfoAC } from 'src/store/slices/auth';
import { useDispatch, useSelector } from 'src/store/store';
//

import { AuthContextType } from './types';

// ----------------------------------------------------------------------

export const AuthContext = createContext<AuthContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Readonly<AuthProviderProps>) {
  const dispatch = useDispatch();
  const { isCheckingRememberedUser, isUserLoggedIn, userData } = useSelector((state) => state.auth);

  useEffect(() => {
    const checkRemenberedUser = async () => {
      dispatch(checkRememberedUserAC());
    };
    checkRemenberedUser();
  }, [dispatch]);

  useEffect(() => {
    const getUserInfo = async () => {
      if (isUserLoggedIn && !isCheckingRememberedUser) {
        dispatch(getUserInfoAC()); //for later
      }
    };
    getUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheckingRememberedUser, isUserLoggedIn, dispatch, getUserInfoAC]);

  return (
    <AuthContext.Provider
      value={{
        isUserLoggedIn,
        userData,isCheckingRememberedUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
