import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { useCallback, useState } from 'react';

type DialogConfigT = {
  title?: React.ReactElement | string;
  message?: React.ReactElement | string;
  confirmText?: string;
  cancelText?: string;
  actionCallback?: (agr: unknown) => void;
};

interface ConfirmDialogProps extends Omit<DialogConfigT, 'actionCallback'> {
  open: boolean;
  onConfirm: VoidFunction;
  onClose: VoidFunction;
}

type ConfirmDialogProviderProps = {
  children: React.ReactNode;
};

type DialogContextT = {
  openDialog?: ({ title, message, actionCallback }: DialogConfigT) => void;
  resetDialog?: () => void;
  onConfirm?: () => void;
  onClose?: () => void;
};

const ConfirmationDialog = ({
  open,
  title,
  message,
  onConfirm,
  onClose,
  cancelText = 'Hủy',
  confirmText: okText = 'Đồng ý',
}: ConfirmDialogProps) => (
  <Dialog
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    open={open}
    onClose={onClose}
    fullWidth
  >
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent id="alert-dialog-description">{message}</DialogContent>

    <DialogActions>
      <Button variant="outlined" onClick={onClose}>
        {cancelText}
      </Button>
      <Button variant="contained" onClick={onConfirm} autoFocus>
        {okText}
      </Button>
    </DialogActions>
  </Dialog>
);

const ConfirmationDialogContext = React.createContext<DialogContextT>({});

const ConfirmationDialogProvider = ({ children }: ConfirmDialogProviderProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogConfig, setDialogConfig] = useState<DialogConfigT>({});

  const openDialog = useCallback(({ title, message, actionCallback }: DialogConfigT) => {
    setDialogOpen(true);
    setDialogConfig({ title, message, actionCallback });
  }, []);

  const resetDialog = useCallback(() => {
    setDialogOpen(false);
    setDialogConfig({});
  }, []);

  const onConfirm = useCallback(() => {
    resetDialog();
    if (dialogConfig.actionCallback) dialogConfig.actionCallback(true);
  }, [dialogConfig, resetDialog]);

  const onClose = useCallback(() => {
    resetDialog();
    if (dialogConfig.actionCallback) dialogConfig.actionCallback(false);
  }, [dialogConfig, resetDialog]);

  return (
    <ConfirmationDialogContext.Provider value={{ openDialog }}>
      <ConfirmationDialog
        open={dialogOpen}
        title={dialogConfig?.title}
        message={dialogConfig?.message}
        onConfirm={onConfirm}
        onClose={onClose}
      />
      {children}
    </ConfirmationDialogContext.Provider>
  );
};

const useConfirmationDialog = () => {
  const { openDialog } = React.useContext(ConfirmationDialogContext);

  const showConfirmationDialog = useCallback(
    (options: DialogConfigT) =>
      new Promise((res) => {
        openDialog?.({ actionCallback: res, ...options });
      }),
    [openDialog]
  );

  return { showConfirmationDialog };
};

export default ConfirmationDialog;
export { ConfirmationDialogProvider, useConfirmationDialog };
