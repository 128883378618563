import Chart from '@components/chart/Chart';
import { Box, Card, Typography } from '@mui/material';
import React, { Fragment } from 'react';

interface Props {
  data: {
    dateTime: string;
    count: number;
  }[];
  formatTooltipValueFn: (amount: number) => string;
  formatYAxisTickFn: (number: number) => string;
  line: {
    title: string;
    name: string;
  };
}

const DashboardChart = ({ data, formatTooltipValueFn, formatYAxisTickFn, line }: Props) => {
  return (
    <Fragment>
      <Card sx={{ p: 2 }}>
        <Box>
          <Box>
            <Typography variant="h5" pb={2}>{line.title}</Typography>
          </Box>

          <Box>
            <Chart
              formatTooltipValueFn={formatTooltipValueFn}
              formatYAxisTickFn={formatYAxisTickFn}
              data={data}
              line={line}
            />
          </Box>
        </Box>
      </Card>
    </Fragment>
  );
};

export default DashboardChart;
