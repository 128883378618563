import { ResponsiveContainer, XAxis, YAxis, CartesianGrid, Tooltip, BarChart, Bar } from 'recharts';
import CustomizeTooltip from './CustomizeTooltip';

interface Props {
  data: any;
  formatTooltipValueFn: (amount: number) => string;
  formatYAxisTickFn: (number: number) => string;
  line: {
    title: string;
    name: string;
  };
}

const Chart = ({ data, formatTooltipValueFn, formatYAxisTickFn, line }: Props) => {
  return (
    <ResponsiveContainer className="chart" height={400}>
      <BarChart width={400} height={600} data={data}>
        <XAxis dataKey="dateTime" tickMargin={5} tickSize={10} tickLine={false} />
        <YAxis tickFormatter={formatYAxisTickFn} width={70} tickLine={false} />
        <CartesianGrid strokeDasharray="0 0" />
        <Tooltip
          content={
            <CustomizeTooltip
              formatTooltipValueFn={formatTooltipValueFn}
              line={line}
              key="tooltip"
            />
          }
          cursor={{ fill: 'transparent' }}
        />
        <Bar maxBarSize={50} dataKey="count" fill="#162a48" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default Chart;
