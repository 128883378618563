import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';
import { Controller, useFormContext } from 'react-hook-form';
import CommonTextField from './CommonTextField';

interface RHFDatePickerProps
  extends Omit<DatePickerProps<any, Date>, 'onChange' | 'renderInput' | 'value'> {
  name: string;
  isRequired?: boolean;
}

function RHFDatePicker({ name, isRequired, ...other }: RHFDatePickerProps) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <DatePicker
            {...field}
            // Auto convert Date to unix timestamp when onChange and vice versa
            value={field.value ? new Date(field.value) : null}
            onChange={(date) => field.onChange(date?.getTime())}
            {...other}
            renderInput={({ onChange, ...params }) => (
              <CommonTextField
                {...params}
                isRequired={isRequired}
                inputProps={{ ...params.inputProps, readOnly: true }}
                InputProps={{ ...params.InputProps, readOnly: true }}
                error={error}
              />
            )}
          />
        );
      }}
    />
  );
}

export default RHFDatePicker;
