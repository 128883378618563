import { Box, Typography, TypographyProps } from '@mui/material';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  isRequired?: boolean;
};

function RequiredLabel({ children, isRequired }: Props) {
  return (
    <>
      {children}
      {isRequired && (
        <Box sx={{ color: 'error.main' }} component="span">
          &nbsp;*
        </Box>
      )}
    </>
  );
}

interface RequiredLabelTypographyProps extends TypographyProps {
  isRequired?: boolean;
}

export function RequiredLabelTypography({
  children,
  isRequired,
  ...other
}: RequiredLabelTypographyProps) {
  return (
    <Typography
      variant="body1"
      component="label"
      color="text.disabled"
      sx={{ py: 1, display: 'block' }}
      {...other}
    >
      <RequiredLabel isRequired={isRequired}>{children}</RequiredLabel>
    </Typography>
  );
}

export default RequiredLabel;
