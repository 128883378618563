// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Autocomplete, TextField, Typography } from '@mui/material';

// ----------------------------------------------------------------------

interface Props {
  name: string;
  label: string;
  height?: number;
  isClearable?: boolean;
  options: any[];
  setIsEdited?: (value: boolean) => void;
  isOptionEqualToValue: any;
  disabled?: boolean;
}

export default function RHFAutocomplete({
  name,
  label,
  height = 250,
  isClearable,
  options,
  setIsEdited,
  disabled = false,
  ...other
}: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <Autocomplete
            disabled={disabled}
            id={name}
            value={field.value}
            onChange={(event, newValue) => {
              event.preventDefault();
              field.onChange(newValue);
              if (setIsEdited) {
                setIsEdited(true);
              }
            }}
            options={options}
            renderInput={(params) => <TextField {...params} label={label} error={!!error} />}
            disablePortal
            disableClearable={!isClearable}
            ListboxProps={{ style: { maxHeight: height } }}
            {...other}
          />
          {error && (
            <Typography color={'#FF4842'} fontSize={12} px={1.5} pt={1}>
              {error.message}
            </Typography>
          )}
        </>
      )}
    />
  );
}
