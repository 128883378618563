import { Controller, useFormContext } from 'react-hook-form';
import SelectField, { SelectFieldProps } from './SelectField';

// ----------------------------------------------------------------------

export type RHFSelectProps = SelectFieldProps & {
  name: string;
  children?: React.ReactNode;
  label: string;
};

export default function RHFSelect({ name, children, isRequired = true, ...other }: RHFSelectProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <SelectField {...field} isRequired={isRequired} error={error} {...other}>
          {children}
        </SelectField>
      )}
    />
  );
}
