// @mui
import { Dialog, DialogProps } from '@mui/material';
import React, { ReactNode } from 'react';

interface CustomDialogProps extends Omit<DialogProps, 'open'> {
  open?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  scroll?: 'body' | 'paper';
  onClose: () => void;
  children: ReactNode;
  sx?: object;
}

export default function CustomDialog({
  open = false,
  maxWidth = 'xs',
  scroll = 'body',
  onClose,
  children,
  sx,
  ...other
}: CustomDialogProps) {
  const closeDialog = (e: React.SyntheticEvent<Element, Event>, reason: string) => {
    if (!!reason && reason === 'backdropClick') {
      return;
    }
    onClose();
  };

  return (
    <Dialog
      fullWidth
      maxWidth={maxWidth}
      scroll={scroll}
      open={open}
      onClose={closeDialog}
      {...other}
    >
      {children}
    </Dialog>
  );
}
