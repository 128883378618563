import { useState } from 'react';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { Alert, IconButton, InputAdornment, Link, Stack, Typography } from '@mui/material';
// layouts
import { getErrorMessage } from '@api/handleApiError';
import { loginAPI } from '@api/main';
import FormProvider, { RHFTextField } from '@components/hook-form';
import Iconify from '@components/iconify';
import LoginLayout from '@layouts/login';
import { PATH_AUTH, PATH_DASHBOARD } from '@routes/paths';
import { dispatch } from '@store/store';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { loginAC } from 'src/store/slices/auth';

// ----------------------------------------------------------------------

type FormValuesProps = {
  userName: string;
  password: string;
  afterSubmit?: string;
};

export default function LoginPage() {
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const LoginSchema = Yup.object().shape({
    userName: Yup.string().email('Email phải đúng định dạng').required('Email không được bỏ trống'),
    password: Yup.string().required('Mật khẩu không được bỏ trống'),
  });

  const defaultValues = {
    userName: '',
    password: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      const res = await loginAPI(data);
      dispatch(loginAC(res.data));
      navigate(PATH_DASHBOARD.root);
    } catch (error) {
      reset();
      setError('afterSubmit', {
        ...error,
        message: getErrorMessage(error),
      });
    }
  };

  return (
    <>
      <Helmet>
        <title> Percommerce Admin | Đăng nhập </title>
      </Helmet>

      <LoginLayout>
        <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
          <Typography variant="h4">Đăng nhập</Typography>
        </Stack>

        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

            <RHFTextField name="userName" label="Email" />

            <RHFTextField
              name="password"
              label="Mật khẩu"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>

          <Stack alignItems="flex-end" sx={{ my: 2 }}>
            <Link
              to={PATH_AUTH.resetPassword}
              component={RouterLink}
              variant="body2"
              color="inherit"
              underline="always"
            >
              Quên mật khẩu?
            </Link>
          </Stack>

          <LoadingButton
            fullWidth
            color="inherit"
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitSuccessful || isSubmitting}
            sx={{
              bgcolor: 'text.primary',
              color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
              '&:hover': {
                bgcolor: 'text.primary',
                color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
              },
            }}
          >
            Đăng nhập
          </LoadingButton>
        </FormProvider>
      </LoginLayout>
    </>
  );
}
