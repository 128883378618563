type enumObjectType = {
  [key: string]: any;
};

type enumObjectDisplayConfigType = {
  [key: string]: {
    title: string;
  };
};

export const enumToSelectOptions = (
  obj: enumObjectType,
  displayObj?: enumObjectDisplayConfigType
) => {
  const keys = Object.keys(obj).filter((key) => isNaN(Number(key)));

  return keys.map((key) => ({
    value: obj[key],
    label: displayObj ? displayObj[key]?.title : key,
  }));
};

export const enumDisplayConfigToSelectOptions = (obj: enumObjectDisplayConfigType) => {
  const keys = Object.keys(obj);

  return keys.map((key) => ({
    value: key,
    label: obj[key].title,
  }));
};

type arrayToSelectOpType = {
  arr: any[];
  labelKey: string;
  valueKey: string;
  conditionKey?: string;
  getAll?: boolean;
};

export const arrayToSelectOptions = ({
  arr,
  labelKey,
  valueKey,
  conditionKey,
  getAll = false,
}: arrayToSelectOpType) =>
  arr.map((c) => ({
    label: c[labelKey],
    value: c[valueKey],
    ...(!!conditionKey && {
      disabled: c[conditionKey],
    }),
    ...(getAll && { item: c }),
  }));

export function convertCurrencyStringToNumber(currencyString: string): number {
  // Loại bỏ dấu chấm trong chuỗi số
  const stringWithoutComma = currencyString.replace(/\./g, '');
  // Chuyển đổi chuỗi số thành số
  const numberValue = parseInt(stringWithoutComma);
  return numberValue;
}

export function base64ToFile(base64: string, filename: string): void {
  try {
    // Loại bỏ tiền tố nếu có (ví dụ: "data:application/zip;base64,")
    const base64Data = base64.split(',')[1] || base64;

    // Kiểm tra chuỗi Base64 hợp lệ
    if (!/^[A-Za-z0-9+/=]*$/.test(base64Data)) {
      throw new Error('Chuỗi Base64 không hợp lệ.');
    }

    const byteString = atob(base64Data);
    const mimeString = base64.split(',')[0]?.split(':')[1]?.split(';')[0] || 'application/text';

    const byteNumbers = new Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      byteNumbers[i] = byteString.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: mimeString });

    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Lỗi trong quá trình giải mã Base64:', error);
  }
}

export const isEmptyValue = (value: unknown): value is null | undefined | '' => {
  return typeof value !== 'number' && !value;
};

// function format number to VND currency (ralative number for label)
export const formatYAxisTickFn = (number: number) => {
  if (number === 0) {
    return `0 ₫`;
  }
  if (number > 0) {
    const symbols = ['', 'K', 'M', 'B', 'T'];
    const scale = 1000;

    let suffixIndex = 0;
    let convertedNumber = number;

    while (convertedNumber >= scale && suffixIndex < symbols.length - 1) {
      convertedNumber /= scale;
      suffixIndex++;
    }

    const roundedNumber = Math.round(convertedNumber * 10) / 10;
    const suffix = symbols[suffixIndex];

    const formattedNumber = roundedNumber.toLocaleString('vi-VN', {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    });

    return `${formattedNumber}${suffix} ₫`;
  }
  return '';
};
