import { Box } from '@mui/material';

//component custom tooltip for chart
const CustomizeTooltip = ({ active, payload, formatTooltipValueFn, line }: any) => {
  if (active && payload && payload.length) {
    return (
      <Box
        sx={{
          display: 'flex',
          border: '1px solid #fff',
          color: 'white',
          backgroundColor: '#162a48',
          padding: 1,
          borderRadius: 1,
        }}
      >
        <Box>
          {line.name} ngày {payload[0].payload.dateTime}
          :&nbsp;
          {formatTooltipValueFn ? formatTooltipValueFn(payload[0].value) : payload[0].value}
        </Box>
      </Box>
    );
  }
  return null;
};

export default CustomizeTooltip;
