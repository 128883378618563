// routes
import { PATH_DASHBOARD } from '@routes/paths';
// components
import Iconify from '@components/iconify';

// ----------------------------------------------------------------------

const icon = (name: string) => <Iconify icon={name} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  sales: icon('charm:shopping-bag'),
  stores: icon('solar:clipboard-list-bold'),
  orders: icon('fa-solid:file-invoice-dollar'),
  rating: icon('fa6-regular:comments'),
  serviceOrder: icon('mdi:customer-service'),
  complaint: icon('hugeicons:complaint'),
  preOrders: icon('carbon:time'),
  resellers: icon('ph:users'),
  vouchers: icon('hugeicons:discount-tag-01'),
};

const AdminNavConfig = [
  {
    subheader: 'SALE',
    items: [{ title: 'Sales', path: PATH_DASHBOARD.root, icon: ICONS.sales }],
  },
  {
    subheader: 'MANAGEMENT',
    items: [
      { title: 'Người dùng', path: PATH_DASHBOARD.users, icon: ICONS.sales },
      { title: 'Gian hàng - Sản phẩm', path: PATH_DASHBOARD.stores, icon: ICONS.stores },
      { title: 'Đơn hàng sản phẩm', path: PATH_DASHBOARD.orders, icon: ICONS.orders },
      { title: 'Đơn hàng dịch vụ', path: PATH_DASHBOARD.serviceOrders, icon: ICONS.serviceOrder },
      { title: 'Đơn hàng đặt trước', path: PATH_DASHBOARD.preOrders, icon: ICONS.preOrders },
      { title: 'Lịch sử giao dịch', path: PATH_DASHBOARD.transactionHistory, icon: ICONS.sales },
      { title: 'Đánh giá', path: PATH_DASHBOARD.rating, icon: ICONS.rating },
      { title: 'Khiếu nại', path: PATH_DASHBOARD.complaint, icon: ICONS.complaint },
    ],
  },
];

const navConfig = [
  {
    subheader: 'SALE',
    items: [{ title: 'Sales', path: PATH_DASHBOARD.root, icon: ICONS.sales }],
  },
  {
    subheader: 'SHOP',
    items: [
      { title: 'Quản lí gian hàng', path: PATH_DASHBOARD.stores, icon: ICONS.stores },
      { title: 'Đơn hàng sản phẩm', path: PATH_DASHBOARD.orders, icon: ICONS.orders },
      { title: 'Đơn hàng dịch vụ', path: PATH_DASHBOARD.serviceOrders, icon: ICONS.serviceOrder },
      { title: 'Đơn hàng đặt trước', path: PATH_DASHBOARD.preOrders, icon: ICONS.preOrders },
      { title: 'Đánh giá', path: PATH_DASHBOARD.rating, icon: ICONS.rating },
      { title: 'Khiếu nại', path: PATH_DASHBOARD.complaint, icon: ICONS.complaint },
      { title: 'Quản lý reseller', path: PATH_DASHBOARD.resellers, icon: ICONS.resellers },
      { title: 'Mã giảm giá', path: PATH_DASHBOARD.vouchers, icon: ICONS.vouchers },
    ],
  },
];

export { AdminNavConfig, navConfig };
