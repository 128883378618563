import { isEmptyValue } from '@utils/utilities';
import omitBy from 'lodash/omitBy';
import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

type FilterType = Record<string, any>;

function useFilter(defaultFilter?: FilterType) {
  const [searchParams, setSearchParams] = useSearchParams(defaultFilter);

  const filter = useMemo(() => Object.fromEntries(searchParams), [searchParams]);

  const setFilter = useCallback(
    (newFilter: FilterType, keepOldValue = true) => {
      setSearchParams(
        (prevSearchParams) => {
          if (keepOldValue) {
            const prevSearchParamsObj = Object.fromEntries(prevSearchParams);
            newFilter = { ...prevSearchParamsObj, ...newFilter };
          }

          return omitBy(newFilter, (value) => isEmptyValue(value));
        },
        {
          replace: true,
        }
      );
    },
    [setSearchParams]
  );

  return [filter, setFilter] as const;
}

export default useFilter;
